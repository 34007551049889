<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@change="(val) => $emit('update:is-active', val)"
        width="800px"
  	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{$t('Add Primary Program')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<!-- BODY -->
            <!-- data table start -->
            <b-table
                ref="deviceList"
                class="position-relative"
                stacked="sm"
                primary-key="uuid"
                show-empty
			    :empty-text="$t('No Data')"
                :items="dataList"
                :fields="tableColumns"
            >
                <!-- Column: Size -->
                <template #cell(check)="data">
                    <!-- {{data}} -->
					<template v-if="data.item.checked">
						<feather-icon icon="CheckSquareIcon" class="text-muted cursor-pointer" size="20" v-if="data.item.isExist"></feather-icon>
						<feather-icon icon="CheckSquareIcon" class="text-primary cursor-pointer" size="20" @click="unSelect(data.item,data.index)" v-else></feather-icon>
                    </template>
                    <feather-icon icon="SquareIcon" class="text-primary cursor-pointer" size="20" @click="select(data.item,data.index)" v-else></feather-icon>
                </template>
                <!-- Column: Size -->
                <template #cell(size)="data">
                    {{data.item.w}}px * {{data.item.h}}px
                </template>
                <template #cell(duration)="data">
                    {{data.item.duration}} s
                </template>

            </b-table>
            <!-- Pagination -->
            <Pagination
                :currentPage="currentPage"
                :pageSize="pageSize"
                :count="dataList.length"
                class="mt-2"
                v-if="dataList.length>0"
            ></Pagination>

            <!-- 保存/取消 -->
            <div class="d-flex mt-2 p-2">
                <b-button
                    variant="primary"
                    class="mr-2"
                    type="button"
                    @click="saveAction"
                >
                    {{$t('Confirm')}}
                </b-button>
                <b-button
                    type="button"
                    variant="outline-secondary"
                    @click="hide"
                >
                    {{$t('Cancel')}}
                </b-button>
            </div>
		</template>
  	</b-sidebar>
</template>

<script>
import {BCard, BRow, BCol, BFormInput, BButton, BTable, BMedia, BAvatar, BLink,
	BBadge, BDropdown, BDropdownItem, BPagination, BTooltip,BSidebar,VBToggle, BListGroup, BListGroupItem,
    BFormCheckbox
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Pagination from "@/components/public/Pagination.vue";
import {getPassProgram} from "@/libs/apis/serverApi"

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BFormInput,
		BButton,
		BTable,
		BMedia, 
		BAvatar, 
		BLink,
		BBadge, 
		BDropdown, 
		BDropdownItem, 
		BPagination,
		BTooltip,
		BSidebar,
		BListGroup, BListGroupItem,
        BFormCheckbox,
		vSelect,
        Pagination
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data () {
		return  {
			tableColumns: [
				{ key: 'uuid', sortable: false },
				{ key: 'name', label:this.$t('Name'), sortable: false },
				{ key: 'size', label: this.$t('Size'), sortable: false },
				{ key: 'duration', label: this.$t('Duration'), sortable: false },
				{ key: 'check', label: '选中状态' },
			],
			loading: false,
			dataList: [],
			pageSize: 12,
			currentPage: 1,
			newSidebarVisible: false,
			renameSidebarVisible: false,
            selectedList:[]
		}
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
            type: Boolean,
            required: true,
		},
        selected: {
            type: Array,
            default () {
                return []
            }
        }
	},
	created () {
		this.getData()
        this.selectedList = [...this.selected]
	},
	methods: {
		getData (page) {
			this.loading = true
			page = page || 1;
            getPassProgram({
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows.map(item=>{
                    const isExist = !!this.selected.find(sitem=>item.uuid==sitem.uuid)
                    item.isExist = isExist
                    item.checked = isExist
                    return item
                });
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
		},
        select (item,index) {
           this.dataList[index].checked = true
        },
        unSelect (item,index) {
           this.dataList[index].checked = false
        },
		saveAction () {
            let selected = this.dataList.filter(item=>item.checked && !item.isExist)
            this.$emit('updata:selected',selected)
            this.$emit('update:is-active',false)
        },
	}
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
