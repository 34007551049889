<template>
	<div class="">
		<div class="d-md-flex align-items-center mb-1">
			<h4 class="flex-1 mb-1 mb-sm-0">
				<feather-icon icon="BoxIcon" size="16"/>
				{{$t('Schedule Name')}}: {{infoData.name}}
			</h4>
			<b-button variant="success" :disabled="saving" v-loading="saving" @click="saveAction()">{{$t('Save')}}</b-button>
			<b-button class="ml-1" variant="outline-secondary" :disabled="saving" @click="cancelAction()">{{$t('Go Back')}}</b-button>
		</div>
		
		<!-- 主节目 -->
		<b-card no-body>
			
			<!-- <b-card-header  class="d-flex align-items-center">
				<b-card-title class="ml-25 ">
					<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('Primary Schedule')}}
				</b-card-title>
				<b-button variant="primary" size="sm" @click="newAction('order')">{{$t('New Program')}}</b-button>
			</b-card-header> -->

			<b-card-body>
				<b-tabs>
					<!-- 主节目 -->
					<b-tab>
						<template #title>
							<h4>{{$t('Primary Schedule')}}</h4>
						</template>
						<div class="d-flex mb-25">
							<p class="flex-1 mt-n25"><small class="text-primary">{{$t('primarySchedulTip')}}</small></p>
							<b-button variant="primary" size="sm" @click="newAction">{{$t('New Program')}}</b-button>
						</div>
						<div class="no-data py-5" v-if="orders.length==0">{{$t('No Program')}}</div>
						<app-timeline class="mt-2" v-else>
							<draggable
								:list="orders"
								group="primarySchedule"
								v-bind="dragOptions"
								@start="isDragging = true"
								@end="isDragging = false"
								class="cursor-move"
								
							>
								<app-timeline-item
									v-for="(item, index) in orders"
									:key="index"
									class="schedule-item"
								>
									<div class="time text-primary">{{getStartTime(index)}}</div>
									<div class="info">
										<div class="d-flex">
											<h5 class="flex-1 font-weight-bold">{{$t('Program Name')}}：{{item.program_name}}</h5>
											<a href="javascript:;" @click="deletePrimarySchedule(item,index)">{{$t('Delete')}}</a>
										</div>
										<small>{{$t('Duration')}}：{{item.duration}} s</small>
									</div>
								</app-timeline-item>
							</draggable>
						</app-timeline>
					</b-tab>
					<b-tab>
						<template #title>
							<h4>{{$t('User Schedule')}}</h4>
						</template>
						<div class="d-flex mb-25">
							<p class="flex-1 mt-n25"><small class="text-primary">{{$t('userSchedulTip')}}</small></p>
							<b-button variant="primary" size="sm" @click="newUserProgramAction">{{$t('New Program')}}</b-button>
						</div>
						
						<div class="no-data py-5" v-if="timing.length==0">{{$t('No Program')}}</div>
						<app-timeline class="mt-2" v-else>
							<app-timeline-item
								v-for="(item, index) in timing"
								:key="index"
								class="schedule-item"
							>
								<div class="time text-primary">{{getTimingStartTime(item.time)}}</div>
								<div class="info">
									<div class="d-flex">
										<h5 class="flex-1 font-weight-bold">{{$t('Program Name')}}：{{item.program_name}}</h5>
										<a href="javascript:;" class="mr-1" @click="setUserSchedule(item,index)">{{$t('Set')}}</a>
										<a href="javascript:;" @click="deleteUserSchedule(item,index)">{{$t('Delete')}}</a>
									</div>
									<small>{{$t('Play Type')}}：{{item.type==1? '叠加播放' : '插入播放'}}</small>
								</div>
							</app-timeline-item>
						</app-timeline>
					</b-tab>
				</b-tabs>
				
			</b-card-body>
		</b-card>

		<!-- 用户节目
		<b-card no-body>
			<b-card-header>
				<div class="d-flex align-items-center">
					<b-card-title class="ml-25 ">
						<span class="mr-50 bullet bullet-sm bullet-primary"/> {{$t('User Schedule')}}
					</b-card-title>
				</div>
				<div>
					<b-button variant="primary" size="sm" @click="newUserProgramAction()">{{$t('New Program')}}</b-button>
				</div>
			</b-card-header>
			<b-card-body>
				<p class="mt-n2 mb-25"><small class="text-primary">{{$t('userSchedulTip')}}</small></p>
				<div class="no-data py-5" v-if="timing.length==0">{{$t('No Program')}}</div>
				<app-timeline class="mt-2" v-else>
					<app-timeline-item
						v-for="(item, index) in timing"
						:key="index"
						class="schedule-item"
					>
						<div class="time text-primary">{{getTimingStartTime(item.time)}}</div>
						<div class="info">
							<div class="d-flex">
								<h5 class="flex-1 font-weight-bold">{{$t('Program Name')}}：{{item.program_name}}</h5>
								<a href="javascript:;" class="mr-1" @click="setUserSchedule(item,index)">{{$t('Set')}}</a>
								<a href="javascript:;" @click="deleteUserSchedule(item,index)">{{$t('Delete')}}</a>
							</div>
							<small>{{$t('Play Type')}}：{{item.type==1? '叠加播放' : '插入播放'}}</small>
						</div>
					</app-timeline-item>
				</app-timeline>
				
			</b-card-body>
		</b-card> -->

		<!-- 选择主节目 -->
		<program
			:is-active.sync="programSidebarVisible"
			v-if="programSidebarVisible"
			:selected="orders"
			@updata:selected="updateProgram"
		></program>
		<!-- 选择用户节目 -->
		<userProgram
			:is-active.sync="userProgramSidebarVisible"
			v-if="userProgramSidebarVisible"
			:selected="timing"
			:activeData="activeUserProgram"
			@updata:selected="updateUserProgram"
		></userProgram>
	</div>
	
</template>

<script>
import { BCard, BCardHeader,BCardBody,BCardTitle, BCardText, BRow, BCol,BButton, BFormInput, BTable, BTooltip,BListGroup, BListGroupItem,BTabs,BTab } from 'bootstrap-vue'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import program from '@/components/schedule/AddPrimaryProgram.vue'
import userProgram from '@/components/schedule/AddUserProgram.vue'
import draggable from 'vuedraggable'
import { getScheduleInfo, saveSchedule } from "@/libs/apis/serverApi";
import {secondsToTimeObj} from "@/libs/utils"

export default {
	components: {
		BCard, BCardHeader,BCardBody,BCardTitle,
		BCardText,
		BRow, BCol,
		BButton,
		BFormInput, BTable, BTooltip,
		draggable,
		BListGroup,
		BListGroupItem,BTabs,BTab,
		AppTimeline,
		AppTimelineItem,
		program,
		userProgram
	},
	data () {
		return {
			playId: this.$route.query.id,
			tableColumns: [
				{ key: 'id', },
				{ key: 'program_name', label: this.$t('Name') },
				{ key: 'time', label: this.$t('Begin Time') },
				{ key: 'duration', label: this.$t('Duration') },
				{ key: 'type', label: this.$t('Play Type') },
				{ key: 'actions',label: this.$t('Actions') },
			],
			dataList: [],
			programSidebarVisible: false,
			infoData: {},
			orders:[],
			rmOrderIds: [],
			timing:[],
			rmTimingIds:[],
            activeData: {},
            isDragging: false,
            dragOptions: {
                animation: 300,
                group: "description",
                disabled: false,
                ghostClass: "ghost"
            },
			saving: false,
			userProgramSidebarVisible: false,
			activeUserProgram: null
		}
	},
	created(){
		this.getData()
	},
	methods: {
		getData () {
			getScheduleInfo({playid: this.playId}).then(res=>{
				this.infoData = res
				this.orders = res.orders.sort(((a,b)=>a.order-b.order))
				this.timing = this.sortTiming(res.timing)
			})
			this.dataList = [{
				id:1, name: '节目名称1', duration: '20:00', begin: "00:00:00", end: "00:20:00", price: "10"
			},{
				id:2, name: '节目名称2', duration: '01:09:00', begin: "00:21:00", end: "01:30:00", price: "10"
			}]
			this.dataList1 = [{
				id:11, name: '节目名称', duration: '20:00', begin: "00:00:00", end: "00:20:00", price: "10"
			},{
				id:12, name: '节目名称', duration: '01:09:00', begin: "00:21:00", end: "01:30:00", price: "10"
			}]
		},
		getStartTime (index) {
			if(index==0) return '00:00:00'
			let seconds = 0
			for(let i=0;i<index;i++){
				seconds += this.orders[i].duration
			}
			let timeObj = secondsToTimeObj(seconds+1)
			return `${timeObj.HH}:${timeObj.mm}:${timeObj.ss}`
		},
		getTimingStartTime (seconds) {
			let timeObj = secondsToTimeObj(seconds)
			return `${timeObj.HH}:${timeObj.mm}:${timeObj.ss}`
		},
		sortTiming (timing) {
			let list = timing.sort((a,b) => a.time - b.time)
			return list
		},
		// 显示主节目添加弹窗
		newAction () {
			this.programSidebarVisible = true
		},
		// 更新主节目列表
		updateProgram (newSelected) {
			let list = newSelected.map((item,index)=>{
				let temp = {}
				temp.playid = this.playId
				temp.uuid = item.uuid
				temp.program_name = item.name
				temp.duration = item.duration
				return temp
			})
			this.orders = [...this.orders,...list]
		},
		// 删除主节目
		deletePrimarySchedule(item,index){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
				title: this.$t('Tips'),//'Please Confirm',
				size: 'sm',
				okVariant: 'danger',
				okTitle: this.$t('Delete'),
				cancelTitle: this.$t('Cancel'),
				cancelVariant: 'outline-secondary',
				hideHeaderClose: false,
				centered: true,
			}).then(isConfirm => {
				if(isConfirm){
					if(item.id){
						this.rmOrderIds.push(item.id)
					}
					this.orders.splice(index,1)
				}
			})
		},
		// 显示用户节目添加弹窗
		newUserProgramAction () {
			this.activeUserProgram = null
			this.userProgramSidebarVisible = true
		},
		// 更新主节目列表
		updateUserProgram (newSelected) {
			if(this.activeUserProgram){
				this.activeUserProgram.type = newSelected.type
				this.activeUserProgram.time = newSelected.time
			}else{
				let temp = {}
				temp.playid = this.playId
				temp.uuid = newSelected.uuid
				temp.program_name = newSelected.name
				temp.duration = newSelected.duration
				temp.type = newSelected.type
				temp.time = newSelected.time
				this.timing.push(temp)
			}
			this.timing = this.sortTiming(this.timing)
		},
		setUserSchedule (item,index) {
			this.activeUserProgram = item
			this.userProgramSidebarVisible = true
		},
		// 删除用户节目
		deleteUserSchedule(item,index){
			this.$bvModal
				.msgBoxConfirm(this.$t('deleteDataTip'), {
				title: this.$t('Tips'),//'Please Confirm',
				size: 'sm',
				okVariant: 'danger',
				okTitle: this.$t('Delete'),
				cancelTitle: this.$t('Cancel'),
				cancelVariant: 'outline-secondary',
				hideHeaderClose: false,
				centered: true,
			}).then(isConfirm => {
				if(isConfirm){
					if(item.id){
						this.rmTimingIds.push(item.id)
					}
					this.timing.splice(index,1)
				}
			})
		},
		// 保存
		saveAction (cbk) {
			let orders = this.orders.map((item, index)=>{
				item.order = index+1
				return item
			})
			let params = {
				playid: this.playId,
				orders: orders,
				rm_oids: this.rmOrderIds,
				timings: this.timing,
				rm_tids: this.rmTimingIds
			}
			saveSchedule (params).then(res=>{
				this.$toast(this.$t('Save Success'))
				cbk && cbk()
			})
		},
		cancelAction () {
			this.$bvModal
				.msgBoxConfirm(this.$t('quitProgramEditTip'), {
					title: this.$t('Tips'),
					size: 'sm',
					okVariant: 'danger',
					okTitle: this.$t('Save And Back'),
					cancelTitle: this.$t('Go Back'),
					cancelVariant: 'outline-secondary',
					hideHeaderClose: false,
					centered: true,
				})
				.then(isConfirm => {
					console.log(isConfirm)
					if (isConfirm === true) {
						this.saveAction(()=>{
							this.$router.back()
						})
					}else if (isConfirm === false){
						this.$router.back()
					}
				})
		}
	}
}
</script>

<style lang="scss">
.timeline-item.schedule-item{
	padding-left: 1rem;
	.time{
		position: relative;
		top: -3px;
	}
	.info{
		padding: 1rem;
		border:1px solid #eee;
		border-radius: 10px;
	}
}
</style>
