<template>
	<b-sidebar
		:visible="isActive"
		bg-variant="white"
		sidebar-class="sidebar-lg"
		shadow
		backdrop
		no-header
		right
		@change="(val) => $emit('update:is-active', val)"
        width="800px"
  	>
		<template #default="{ hide }">
			<!-- Header -->
			<div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
				<h5 class="mb-0">
					{{activeData ? `${$t('Set User Program')}：${activeData.program_name}` :$t('Add User Program')}}
				</h5>

				<feather-icon
					class="ml-1 cursor-pointer"
					icon="XIcon"
					size="16"
					@click="hide"
				/>
			</div>

			<!-- BODY -->
            <!-- data table start -->
            <b-table
                ref="deviceList"
                class="position-relative"
                stacked="sm"
                primary-key="uuid"
                show-empty
			    :empty-text="$t('No Data')"
                :items="dataList"
                :fields="tableColumns"
				v-if="!activeData"
            >
                <!-- Column: Size -->
                <template #cell(check)="data">
                    <!-- {{data}} -->
                    <template v-if="data.item.checked">
						<feather-icon icon="CheckCircleIcon" class="text-muted cursor-pointer" size="20" v-if="data.item.isExist"></feather-icon>
						<feather-icon icon="CheckCircleIcon" class="text-primary cursor-pointer" size="20" v-else></feather-icon>
                    </template>
                    <feather-icon icon="CircleIcon" class="text-primary cursor-pointer" size="20" @click="select(data.item,data.index)" v-else></feather-icon>
                </template>
                <!-- Column: Size -->
                <template #cell(size)="data">
                    {{data.item.w}}px * {{data.item.h}}px
                </template>
                <template #cell(duration)="data">
                    {{data.item.duration}} s
                </template>

            </b-table>
            <!-- Pagination -->
            <Pagination
                :currentPage="currentPage"
                :pageSize="pageSize"
                :count="dataList.length"
                class="mt-2"
                v-if="dataList.length>0"
            ></Pagination>

			<div class="p-2" v-if="selectedItem">
				<h5>{{$t('Setting')}}</h5>
					<!-- Form -->
				<b-form
					class="p-1"
					@submit.prevent="saveAction"
				>
					<b-form-group :label="$t('Selected Program')" v-if="!activeData">{{selectedItem.name}}</b-form-group>
					
					<b-form-group
						:label="$t('Start Time')"
						label-for="time"
					>
						<b-time
							v-model="formData.time"
							id="time"
							v-bind="timeLabels[locale] || {}"
							:locale="locale"
							@context="onTimeChange"
						/>
					</b-form-group>

					<b-form-group
						:label="$t('Play Type')"
						label-for="time"
					>
						<b-form-radio-group
							id="time"
							v-model="formData.type"
							aria-controls="ex-disabled-readonly"
						>
							<b-form-radio :value="0">{{$t('userProgramPlayType1')}}</b-form-radio>
							<b-form-radio :value="1">{{$t('userProgramPlayType2')}}</b-form-radio>
						</b-form-radio-group>
					</b-form-group>

					<!-- Form Actions -->
					<div class="d-flex mt-2">
						<b-button
							variant="primary"
							class="mr-2"
							type="submit"
						>
							{{activeData ? $t('Save') : $t('Add')}}
						</b-button>
						<b-button
							type="button"
							variant="outline-secondary"
							@click="hide"
						>
							{{$t('Cancel')}}
						
						</b-button>
					</div>

				</b-form>
			</div>
		</template>
  	</b-sidebar>
</template>

<script>
import {BCard, BRow, BCol, BFormRadioGroup,BFormRadio, BButton, BTable, BForm, BFormGroup,BSidebar,VBToggle, BListGroup, BListGroupItem,
    BFormCheckbox,BTime
} from 'bootstrap-vue'
import Pagination from "@/components/public/Pagination.vue";
import {getPassProgram} from "@/libs/apis/serverApi"
import {secondsToTimeObj} from "@/libs/utils"

export default {
	components: {
		BCard,
		BRow,
		BCol,
		BButton,
		BTable,
		BForm,
		BFormGroup,
		BFormRadioGroup,
		BFormRadio,
		BTime,
		BSidebar,
		BListGroup, BListGroupItem,
        BFormCheckbox,
        Pagination,
	},
	directives: {
		'b-toggle': VBToggle,
	},
	data () {
		return  {
			tableColumns: [
				{ key: 'uuid', sortable: false },
				{ key: 'name', label:this.$t('Name'), sortable: false },
				{ key: 'size', label: this.$t('Size'), sortable: false },
				{ key: 'duration', label: this.$t('Duration'), sortable: false },
				{ key: 'check', label: '选中状态' },
			],
			loading: false,
			dataList: [],
			pageSize: 6,
			currentPage: 1,
			newSidebarVisible: false,
			renameSidebarVisible: false,
			selectedItem: null,
			timeLabels: {
				zh: {
					labelHours: '小时',
					labelMinutes: '分钟',
					labelSeconds: '秒',
					labelAmpm: '上午下午',
					labelAm: '上午',
					labelPm: '下午',
					labelIncrement: '增量',
					labelDecrement: '减量',
					labelSelected: '选定时间',
					labelNoTimeSelected: '没有选择时间',
				},
			},
			formData: {
				time: '00:00',
				type: 0
			}
		}
	},
	model: {
		prop: 'isActive',
		event: 'update:is-active',
	},
	props: {
		isActive: {
            type: Boolean,
            required: true,
		},
        selected: {
            type: Array,
            default () {
                return []
            }
        },
        activeData: {
            type: Object,
            default () {
                return null
            }
        }
	},
	computed: {
		locale () {
			let locale = this.$store.state.app.locale
			if(locale == 'zh_CN') return 'zh'
			return locale
		}
		
	},
	created () {
		if(!this.activeData){
			this.getData()
		}else{
			this.selectedItem = this.activeData
			this.formData.type = this.activeData.type
			let timeObj = secondsToTimeObj(this.activeData.time)
			this.formData.time = timeObj.HH+':'+timeObj.mm
		}
	},
	methods: {
		getData (page) {
			console.log(this.selected[0] && this.selected[0].uuid)
			this.loading = true
			page = page || 1;
            getPassProgram({
				offset: (page - 1) * this.pageSize,
				limit: this.pageSize,
			}).then((res) => {
				this.loading = false
				this.dataList = res.rows.map(item=>{
                    const isExist = !!this.selected.find(sitem=>item.uuid==sitem.uuid)
					console.log(isExist)
                    item.isExist = isExist
                    item.checked = isExist
                    return item
                });
				this.currentPage = page;
			}, err=>{
				this.loading = false
				this.$toast(err.message)
			});
		},
        select (item,index) {
			this.dataList.forEach((item,itemIndex)=>{
				if(!item.isExist){
					item.checked = itemIndex==index
				}
				
			})
			this.selectedItem = item
        },
		onTimeChange (e) {
			if(!e.value) return
			this.formData.seconds =  e.hours*3600 + e.minutes*60
		},
		saveAction () {
            let selected = this.selectedItem
			selected.type = this.formData.type
			selected.time = this.formData.seconds
            this.$emit('updata:selected',selected)
            this.$emit('update:is-active',false)
        },
	}
}
</script>


<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
